.error-fallback {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  background-color: #f7f7f7;
  font-family: sans-serif;
  overflow: auto;
  padding: 2rem;
  z-index: 10000000000000;
}

.error-fallback-content {
  text-align: center;
  padding: 2rem;
  border: 1px solid #ddd;
  border-radius: 0.5rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  line-height: 1.5;
  max-width: 80ch;
}

.error-fallback-content > *:is(p, pre) {
  margin-bottom: 0;
  font-size: 1.5rem;
}

.error-fallback-content p.error-fallback-title {
  font-size: 1.75rem;
  font-weight: 700;
  color: #000;
}

pre.error-fallback-message {
  color: #fff;
  background-color: #e74c3c;
  padding: 0.15rem 0.35rem;
  border-radius: 0.25rem;
  font-weight: 500;
  white-space: normal;
  max-height: calc(10ch + 1rem);
  max-width: 100%;
  overflow: auto;
  scrollbar-width: thin;
  scrollbar-color: white #e74c3c;
}

pre.error-fallback-info {
  color: gray;
  font-size: 1rem;
  white-space: normal;
}

.error-fallback-content button {
  padding: 0.5rem 1rem;
  font-size: 18px;
  background-color: #3498db;
  color: #fff;
  border: none;
  border-radius: 0.35rem;
  transition: background-color 0.3s ease;
  cursor: pointer;
}

.error-fallback-content button:hover {
  background-color: #2980b9;
}

.error-fallback-actions {
  display: flex;
  flex-direction: row;
  gap: 16px;
}

.error-fallback-actions > * {
  flex: 1;
}
